import { QueryMapping } from 'src/hooks/use-breakpoint';
import { MOBILE_TOP_NAV_HEIGHT } from 'src/utils/constants/keys';
import { makeStyles } from 'tss-react/mui';

export const BOTTOM_NAV_ZINDEX = 999;

export const useStyles = makeStyles()((theme) => {
  const mobile = QueryMapping.mobile;
  return {
    hiddenOnMobile: {
      height: `${MOBILE_TOP_NAV_HEIGHT}px !important`,
      display: 'none',
      boxShadow: '0px -2px 4px 0px rgba(0, 0, 0, 0.25)',
      [mobile]: {
        display: 'flex',
        position: 'fixed !important' as 'fixed',
        zIndex: BOTTOM_NAV_ZINDEX,
        left: '0px',
        right: '0px',
        bottom: '0px !important',
        WebkitOverflowScrolling: 'touch',
      },
    },
    container: {
      backgroundColor: theme.custom.header,
      height: '100%',
    },

    main: {
      padding: '10px 0',
      justifyContent: 'flex-start',
      height: '100%',
      gap: 8,
      backgroundColor: theme.custom.bg[1],
      overflowX: 'auto',
    },
    item: {
      minWidth: 'unset',
      maxWidth: 'unset',
      flex: 'unset',
      padding: 0,
      color: theme.custom.content[1],
      fontSize: 12,
      lineHeight: 1,
      whiteSpace: 'nowrap',
      '& svg': { width: 16, height: 16, marginBottom: theme.spacing(1) },
    },
    selected: {
      paddingTop: '0px !important',
      fontSize: '12px !important',
    },
  };
});
