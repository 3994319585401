import {
  Box,
  Drawer,
  DrawerProps,
  IconButton,
  Typography,
} from '@mui/material';
import { useAtomValue } from 'jotai';
import _ from 'lodash';
import { useKeyPressEvent } from 'react-use';
import CloseIcon from 'src/assets/icons/close.svg';
import { useBreakpoint } from 'src/hooks/use-breakpoint';
import { useMyPathname } from 'src/hooks/use-locale';
import { profileAtom } from 'src/store/auth';
import {
  DESKTOP_BS_WIDTH,
  DESKTOP_NAV_BAR_HEIGHT,
  MOBILE_NAV_BAR_HEIGHT,
} from 'src/utils/constants/keys';
import { Paths } from 'src/utils/constants/path';
import { MyDevices } from 'src/utils/helpers/etc';
import MyMoneyDropdown from '../my-money-dropdown';
import Socials from '../socials';

type UserDrawerProps = {
  children: React.ReactNode;
  handleClose: () => void;
  open: boolean;
  hideCoinSelector?: boolean;
};

export const useDrawerVariant = () => {
  const { pathname } = useMyPathname();
  const mobile = useBreakpoint(MyDevices.MOBILE);

  const variant = (
    pathname.startsWith(Paths.Dashboard) || mobile ? 'temporary' : 'persistent'
  ) as DrawerProps['variant'];

  return {
    variant,
    shift: variant === 'persistent',
  };
};

const UserDrawer = ({
  children,
  handleClose,
  open,
  hideCoinSelector,
}: UserDrawerProps) => {
  const { variant } = useDrawerVariant();
  const profile = useAtomValue(profileAtom);

  useKeyPressEvent('Escape', handleClose);

  return (
    <Drawer
      anchor="right"
      PaperProps={{
        sx: {
          width: { xs: '100%', sm: DESKTOP_BS_WIDTH },
          bgcolor: 'custom.bg.1',
          flexShrink: 0,
          p: '0',
          pb: 2,
          borderLeft: '1px solid',
          borderColor: 'divider',
        },
      }}
      open={open}
      onClose={handleClose}
      variant={variant}
      ModalProps={{
        keepMounted: true,
        disablePortal: true,
      }}>
      <Box sx={{ flex: 1 }}>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            padding: '8px 14px',
            mb: 2,
            bgcolor: 'custom.header',
            height: { xs: MOBILE_NAV_BAR_HEIGHT, sm: DESKTOP_NAV_BAR_HEIGHT },
          }}>
          <Typography
            align="center"
            sx={{
              fontWeight: 700,
              width: '31px',
              height: '31px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              bgcolor: 'custom.bg.2',
              borderRadius: '3px',
              textTransform: 'uppercase',
            }}>
            {_.first(profile?.username)}
          </Typography>
          <Typography
            align="center"
            fontSize={14}
            fontWeight={600}
            sx={{
              flex: 1,
              textAlign: 'left',
              marginLeft: '10px',
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              marginRight: '4px',
            }}>
            {profile?.username}
          </Typography>
          {!hideCoinSelector && <MyMoneyDropdown />}
          <IconButton
            onClick={handleClose}
            size="large"
            sx={{ marginLeft: '24px' }}>
            <CloseIcon />
          </IconButton>
        </Box>
        <Box sx={{ padding: '0 14px' }}>{children}</Box>
      </Box>
      <Socials
        containerProps={{ sx: { display: 'flex', justifyContent: 'center' } }}
      />
    </Drawer>
  );
};

export default UserDrawer;
