import {
  FormControl,
  FormHelperText,
  FormLabel,
  InputBase,
  styled,
  type FormControlProps,
  type FormHelperTextProps,
  type FormLabelProps,
  type InputBaseProps,
} from '@mui/material';
import _ from 'lodash';
import React, { useCallback, WheelEventHandler } from 'react';
import { BN } from 'src/utils/helpers/big-number';

export interface MyInputProps extends InputBaseProps {
  FormLabelProps?: FormLabelProps;
  FormControlProps?: FormControlProps;
  FormHelperTextProps?: FormHelperTextProps;
  label?: React.ReactNode;
  helperText?: React.ReactNode;
  pattern?: RegExp | ((txt: string) => boolean);
  padFloatZero?: boolean;
  pastePattern?: RegExp;
}

export const UnstyledMyInput = styled(InputBase)(({ theme }) => {
  return {
    backgroundColor: 'transparent',
    border: 0,
    '& input': { padding: 0 },
  };
});

export const MyInput = React.forwardRef<any, MyInputProps>(function MyInput(
  props,
  ref,
) {
  const {
    error,
    label,
    required,
    fullWidth,
    helperText,
    pattern,
    onChange,
    FormLabelProps,
    FormControlProps,
    FormHelperTextProps,
    inputRef,
    padFloatZero,
    pastePattern,
    onWheel,
    ...rest
  } = props;

  const _onChange = useCallback<React.ChangeEventHandler<HTMLInputElement>>(
    (e) => {
      if (!pattern) {
        return onChange?.(e);
      }

      const ok = _.isFunction(pattern)
        ? pattern(e.target.value)
        : pattern.test(e.target.value);

      if (ok) {
        onChange?.(e);
      }
    },
    [onChange, pattern],
  );

  return (
    <FormControl
      {...FormControlProps}
      fullWidth={fullWidth}
      error={error}
      ref={ref}
      required={required}>
      {label && (
        <FormLabel
          {...FormLabelProps}
          sx={{
            ...FormLabelProps?.sx,
            textAlign: 'start',
          }}>
          {label}
        </FormLabel>
      )}
      <InputBase
        {...rest}
        onWheel={(e) => {
          //prevent mousewheel in input of type number
          const target = e.target as HTMLInputElement;
          if (target.type === 'number') {
            target?.blur();
          }
          onWheel?.(e);
        }}
        type={rest.type}
        onBlur={
          padFloatZero
            ? (e) => {
                const val = new BN(e.target.value);
                if (val.isNaN()) return;
                e.target.value = val.toString();
                // @ts-ignore
                _onChange?.(e);
                rest?.onBlur?.(e);
              }
            : rest.onBlur
        }
        onPaste={
          pastePattern
            ? (e) => {
                const text = e.clipboardData.getData('text/plain');
                const matches = text.match(pastePattern);
                if (matches) {
                  _.set(e, ['target', 'value'], _.first(matches));
                  _onChange(e as any);
                }
              }
            : rest.onPaste
        }
        onChange={_onChange}
        inputRef={inputRef}
      />
      {helperText && (
        <FormHelperText component="div" {...FormHelperTextProps}>
          {helperText}
        </FormHelperText>
      )}
    </FormControl>
  );
});
