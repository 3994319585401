import _ from 'lodash';
import moment from 'moment';
import { getBRLocale } from 'src/utils/helpers/i18n';
import { toEventSlug } from 'src/utils/helpers/sport';
import { request } from 'src/utils/request';
import {
  BaseEvent,
  DetailEventInterface,
  FixtureStatus,
  ICategory,
} from 'src/utils/types/event';
import { BaseOutcome } from 'src/utils/types/outcome';
import {
  BaseResponse,
  IPagination,
  PaginationResponse,
} from 'src/utils/types/response';
import {
  EventTimeRanges,
  GetFixtureListRequest,
} from 'src/utils/types/sportsbook';

const convertMongooseIdToNumber = (id: string) => {
  return +(
    parseInt(id.substring(0, 8), 16) +
    '' +
    String(parseInt(id.substring(18), 16)).padStart(10, '0')
  );
};

export const getTimeRange = (
  prev?: EventTimeRanges,
  prevData?: IPagination<BaseEvent<BaseOutcome>, any>,
) => {
  const head = _.first(prevData?.items);
  const tail = _.last(prevData?.items);

  if (!head || !tail) return prev;

  if (!prev) {
    return {
      startTimeMin: head.startTime,
      startTimeMax: tail.startTime,
      startTimeMinId: head._id,
      startTimeMaxId: tail._id,
    };
  }

  let minTime = '';
  let minId = '';
  let maxTime = '';
  let maxId = '';

  // calculate minTime and minId
  if (prev.startTimeMin === head.startTime) {
    minTime = head.startTime;
    minId =
      _.minBy([prev.startTimeMinId, head._id], convertMongooseIdToNumber) || '';
  } else {
    const minData = _.minBy(
      [
        { id: prev.startTimeMinId, time: prev.startTimeMin },
        { id: head._id, time: head.startTime },
      ],
      (o) => moment(o.time).valueOf(),
    );
    minTime = minData?.time || '';
    minId = minData?.id || '';
  }

  //calculate maxTime and maxId
  if (prev.startTimeMax === tail.startTime) {
    maxTime = tail.startTime;
    maxId =
      _.maxBy([prev.startTimeMaxId, tail._id], convertMongooseIdToNumber) || '';
  } else {
    const maxData = _.maxBy(
      [
        { id: prev.startTimeMaxId, time: prev.startTimeMax },
        { id: tail._id, time: tail.startTime },
      ],
      (o) => moment(o.time).valueOf(),
    );
    maxTime = maxData?.time || '';
    maxId = maxData?.id || '';
  }

  return {
    startTimeMin: minTime,
    startTimeMax: maxTime,
    startTimeMinId: minId,
    startTimeMaxId: maxId,
  } satisfies EventTimeRanges;
};

export const getFixtureList = async (
  params: GetFixtureListRequest,
): Promise<IPagination<BaseEvent, { __ranges: EventTimeRanges }>> => {
  const { page_index, page_size, status, lang, __ranges, ...rest } = params;

  const locale = getBRLocale(lang);

  const { data } = await request.get<PaginationResponse<BaseEvent>>(
    '/fixture-events/list-fixture-events',
    {
      params: {
        ...rest,
        ...__ranges,
        size: page_size,
        status,
        live: status === FixtureStatus.LIVE ? status : undefined,
        lang: locale,
        lang_fields: ['competitor1', 'competitor2', 'mainPopularOutcomes'],
      },
    },
  );

  const range = getTimeRange(params.__ranges, data.data);

  const returned = {
    items: _.map(
      data.data.items,
      (o) =>
        ({
          ...o,
          ...formatData(o, locale),
          _eventSlug: toEventSlug(o),
        }) as BaseEvent,
    ),
    meta: {
      ...data.data.meta,
      __ranges: range,
    },
  };

  return returned;
};

interface GetHotEventsProps {
  size: number;
  lang: string;
  sort_by: String;
  order_by: 'ASC' | 'DESC';
}
export const getHotEvents = async ({
  size,
  lang,
  sort_by,
  order_by,
}: GetHotEventsProps) => {
  const locale = getBRLocale(lang);

  const { data } = await request.get<PaginationResponse<BaseEvent>>(
    '/hot-event',
    {
      params: {
        size,
        lang: locale,
        lang_fields: ['competitor1', 'competitor2', 'mainPopularOutcomes'],
        sort_by,
        order_by,
      },
    },
  );

  const returned = {
    items: _.map(
      data.data.items,
      (o) =>
        ({
          ...o,
          ...formatData(o, locale),
          _eventSlug: toEventSlug(o),
        }) as BaseEvent,
    ),
    meta: {
      ...data.data.meta,
    },
  };

  return returned;
};
export const fetchCategories = async (params: {
  sport: string;
  lang?: string | undefined;
  status?: FixtureStatus;
}) => {
  if (!params.sport) return [] as ICategory[];
  const locale = getBRLocale(params.lang);
  const { data } = await request.get<PaginationResponse<ICategory>>(
    `/tournament/leagues`,
    {
      params: {
        slug: params.sport,
        live: params.status === FixtureStatus.LIVE ? 1 : undefined,
        lang: locale,
      },
    },
  );

  const result = _.map(data.data.items, (o) =>
    formatDataFetchCategories(o, locale),
  );
  return result;
};

export const getOutrightSportV2 = async (slug: string, locale?: string) => {
  const lang = getBRLocale(locale);

  const { data } = await request.get<PaginationResponse<ICategory>>(
    `/outright-events/outright-sport`,
    {
      params: {
        slug,
        lang,
        lang_fields: ['name', 'mainPopularOutcomes'],
      },
    },
  );

  data.data.items.forEach((outright) => {
    outright?.events?.forEach((e) => {
      e['_eventSlug'] = toEventSlug(e);
    });
  });
  const result = _.map(data.data.items, (o) =>
    formatDataOutrightSportV2(o, lang),
  );

  return { items: result, meta: data.data.meta };
};

export const getDetailFixtureV2 = async (eventId?: string, locale?: string) => {
  const url = `fixture-events/detail`;
  const lang = getBRLocale(locale);

  const {
    data: { data },
  } = await request.get<BaseResponse<DetailEventInterface>>(url, {
    params: {
      eventId,
      lang,
      lang_fields: ['competitor1', 'competitor2', 'templates'],
    },
  });
  return convertDetailEvent(data, lang);
};

export const getDetailOutrightV2 = async (
  eventId?: string,
  locale?: string,
) => {
  const url = `outright-events/detail`;
  const lang = getBRLocale(locale);

  const {
    data: { data },
  } = await request.get<BaseResponse<DetailEventInterface>>(url, {
    params: {
      eventId,
      lang,
      lang_fields: ['name', 'templates'],
      fields: 'sport.logo,sport.color,sport.name,sport.logoName',
    },
  });

  return convertDetailEvent(data, lang);
};

// const getGroupNameKey = (
//   template: TemplateInterface,
//   outcomeGroup: OutcomeGroupInterface,
// ) => {
//   // TODO: for case groupName = null
//   const gName = (_.get(outcomeGroup, 'groupName', '') || '')?.replace(
//     /[^\d\w]/g,
//     '_',
//   );

//   return `${_.get(template, 'groupkey', '')}-${gName}`;
// };

const convertDetailEvent = (_data: DetailEventInterface, lang?: string) => {
  const data: DetailEventInterface = {
    ..._data,
    fixtureMarketGroups: _.uniqWith(
      _data.fixtureMarketGroups,
      (a, b) => a._id === b._id,
    ),
    eventName: _data?.name ?? _data?.eventName,
  };
  // const { tournament, fixtureMarketGroups } = data;
  if (!lang) return data;
  const languages = _.get(data, ['languages', lang]);
  // const tournamentLang = _.get(data, ['tournament', 'languages', lang]);
  const periodScores = _.chain([data.sportEventStatus?.period_scores])
    .flatMap((o) => o?.period_score)
    .compact()
    .value();

  // const result: DetailEventInterface = {
  //   ...data,
  //   ...(!!fixtureMarketGroups && {
  //     fixtureMarketGroups: _.map(fixtureMarketGroups, (group) => ({
  //       ..._.omit(group, 'languages'),
  //       name: _.get(
  //         group,
  //         ['languages', lang, 'name'],
  //         _.get(group, 'name', ''),
  //       ),
  //     })),
  //   }),
  //   competitor1: _.get(languages, 'competitor1', _.get(data, 'competitor1')),
  //   competitor2: _.get(languages, 'competitor2', _.get(data, 'competitor2')),
  //   tournament: {
  //     ..._.omit(tournament, 'languages'),
  //     name: _.get(tournamentLang, ['name'], _.get(tournament, 'name')),
  //   },
  //   templates: _.map(data.templates, (template) => {
  //     const mKey = _.get(template, 'groupkey', '');

  //     return {
  //       ...template,
  //       marketName: _.get(
  //         languages,
  //         ['marketNames', mKey],
  //         _.get(template, 'marketName'),
  //       ),
  //       markets: _.map(template.markets, (marketItem) => ({
  //         ...marketItem,
  //         outcomeGroups: _.map(marketItem.outcomeGroups, (outcomeGroup) => {
  //           const groupName = _.get(
  //             languages,
  //             ['groupNames', getGroupNameKey(template, outcomeGroup)],
  //             _.get(outcomeGroup, 'groupName'),
  //           );

  //           return {
  //             ...outcomeGroup,
  //             groupName: groupName,
  //             outcomes: _.map(outcomeGroup.outcomes, (outcome) => {
  //               const languageValue = _.get(languages, [
  //                 'marketOutcomes',
  //                 outcome._id || '',
  //               ]);
  //               const outcomeName = _.get(
  //                 languageValue,
  //                 'outcomeName',
  //                 _.get(outcome, 'outcomeName'),
  //               );
  //               return {
  //                 ...outcome,
  //                 marketName: _.get(
  //                   languageValue,
  //                   'marketName',
  //                   _.get(outcome, 'marketName'),
  //                 ),
  //                 outcomeGroupName: _.get(
  //                   languageValue,
  //                   'outcomeGroupName',
  //                   _.get(outcome, 'outcomeGroupName'),
  //                 ),
  //                 outcomeName:
  //                   outcomeName === '0'
  //                     ? _.get(outcome, 'outcomeName')
  //                     : outcomeName,
  //               };
  //             }),
  //           };
  //         }),
  //       })),
  //     };
  //   }),
  //   sportEventStatus: {
  //     ...data.sportEventStatus,
  //     period_scores: {
  //       ...data.sportEventStatus?.period_scores,
  //       period_score: periodScores,
  //     },
  //   },
  // };

  const result: DetailEventInterface = {
    ..._.omit(data, 'languages'),
    competitor1: _.get(languages, 'competitor1', _.get(data, 'competitor1')),
    competitor2: _.get(languages, 'competitor2', _.get(data, 'competitor2')),
    name: _.get(languages, 'name', _.get(data, 'name')),
    sportEventStatus: {
      ...data.sportEventStatus,
      period_scores: {
        ...data.sportEventStatus?.period_scores,
        period_score: periodScores,
      },
    },
  };

  return result;
};

const formatData = (_data: BaseEvent, lang: string | undefined) => {
  const data: BaseEvent = {
    ..._data,
    eventName: _data?.name ?? _data?.eventName,
  };

  const { languages, tournament } = data;
  if (languages === undefined || lang === undefined) return data;
  const _language = _.get(data, ['languages', lang]);
  const result: BaseEvent = {
    ..._.omit(data, 'languages'),
    competitor1: _.get(_language, 'competitor1', data.competitor1),
    competitor2: _.get(_language, 'competitor2', data.competitor2),
  };

  // const result: BaseEvent = {
  //   ..._.omit(data, 'languages'),
  //   competitor1: _.get(_language, 'competitor1', data.competitor1),
  //   competitor2: _.get(_language, 'competitor2', data.competitor2),
  //   tournament: tournament
  //     ? {
  //         ..._.omit(tournament, 'languages'),
  //         ..._.get(tournament, ['languages', lang]),
  //       }
  //     : undefined,
  //   mainPopularOutcomes: {
  //     ...data.mainPopularOutcomes,
  //     marketOutcomes: _.chain(data.mainPopularOutcomes.marketOutcomes)
  //       .map((o) => {
  //         if (!o) return o;
  //         return {
  //           ...o,
  //           marketName: _.get(
  //             _language,
  //             ['marketNames', o.marketId],
  //             o.marketName,
  //           ),
  //           outcomeName: o._id
  //             ? _.get(
  //                 _language,
  //                 ['marketOutcomes', o._id, 'outcomeName'],
  //                 o.outcomeName,
  //               )
  //             : o.outcomeName,
  //           outcomeGroupName: o._id
  //             ? _.get(
  //                 _language,
  //                 ['marketOutcomes', o._id, 'outcomeGroupName'],
  //                 o.outcomeGroupName,
  //               )
  //             : o.outcomeGroupName,
  //         };
  //       })
  //       .value(),
  //   },
  // };

  return result;
};

const formatDataOutrightSportV2 = (
  _data: ICategory,
  lang: string | undefined,
) => {
  const data: ICategory = {
    ..._data,
    events: _.chain(_data.events)
      .map((o) => {
        return {
          ...o,
          eventName: o?.name ?? o?.eventName,
        };
      })
      .value(),
  };
  if (lang === undefined) return data;

  const result: ICategory = {
    ...data,
    category: data.events
      ? _.get(
          data?.events[0],
          ['category', 'languages', lang, 'customName'],
          data.category,
        )
      : data.category,
    events: _.chain(data.events)
      .map((o) => {
        return {
          ..._.omit(o, 'languages'),
          category: o.category
            ? {
                ..._.omit(o.category, 'languages'),
                name: _.get(
                  o.category.languages,
                  [lang, 'customName'],
                  o.category,
                ),
              }
            : undefined,

          sport: {
            ..._.omit(o.sport, 'languages'),
            name: _.get(o.sport, ['languages', 'name'], o.sport?.name) as any,
          },
        };
      })
      .value(),
  };

  // const result: ICategory = {
  //   ...data,
  // }

  // const result: ICategory = {
  //   ...data,
  //   category: data.events
  //     ? _.get(
  //         data?.events[0],
  //         ['category', 'languages', lang, 'customName'],
  //         data.category,
  //       )
  //     : data.category,
  //   events: _.chain(data.events)
  //     .map((o) => {
  //       return {
  //         ..._.omit(o, 'languages'),
  //         category: o.category
  //           ? {
  //               ..._.omit(o.category, 'languages'),
  //               name: _.get(
  //                 o.category.languages,
  //                 [lang, 'customName'],
  //                 o.category,
  //               ),
  //             }
  //           : undefined,
  //         mainPopularOutcomes: _.isNull(
  //           o.mainPopularOutcomes.fixturePopularMarket,
  //         )
  //           ? o.mainPopularOutcomes
  //           : {
  //               ...o.mainPopularOutcomes,
  //               marketOutcomes: _.chain(o.mainPopularOutcomes.marketOutcomes)
  //                 .map((marketOutcome) => {
  //                   if (!marketOutcome) return marketOutcome;
  //                   return {
  //                     ...marketOutcome,
  //                     marketName: marketOutcome._id
  //                       ? _.get(
  //                           o.languages,
  //                           [
  //                             lang,
  //                             'marketOutcomes',
  //                             marketOutcome._id,
  //                             'marketName',
  //                           ],
  //                           marketOutcome.marketName,
  //                         )
  //                       : marketOutcome.marketName,
  //                     outcomeName: marketOutcome._id
  //                       ? _.get(
  //                           o.languages,
  //                           [
  //                             lang,
  //                             'marketOutcomes',
  //                             marketOutcome._id,
  //                             'outcomeName',
  //                           ],
  //                           marketOutcome.outcomeName,
  //                         )
  //                       : marketOutcome.outcomeName,
  //                   };
  //                 })
  //                 .value(),
  //             },
  //         sport: {
  //           ..._.omit(o.sport, 'languages'),
  //           name: _.get(o.sport, ['languages', 'name'], o.sport?.name),
  //         },
  //       };
  //     })
  //     .value(),
  // };

  return result;
};

const formatDataFetchCategories = (
  data: ICategory,
  lang: string | undefined,
) => {
  if (lang === undefined) return data;

  const result: ICategory = {
    ..._.omit(data, 'languages'),
    categoryName: _.get(
      data,
      ['languages', lang, 'customName'],
      data.categoryName,
    ),
    tournaments: _.chain(data.tournaments)
      .map((o) => {
        return {
          ..._.omit(o, 'languages'),
          name: _.get(o, ['languages', lang, 'name'], o.name),
        };
      })
      .value(),
  };

  return result;
};
