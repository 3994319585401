// @ts-nocheck
/// https://github.dev/remix-run/react-router/blob/3679bafb744c1fb1b495eefdacce0a4fbbb9197a/packages/react-router/modules/generatePath.js#L12
import { compile } from 'path-to-regexp';

const cache = {};
const cacheLimit = 1000;
let cacheCount = 0;

function compilePath(path) {
  if (cache[path]) return cache[path];

  const generator = compile(path);

  if (cacheCount < cacheLimit) {
    cache[path] = generator;
    cacheCount++;
  }

  return generator;
}

/**
 * Public API for generating a URL pathname from a path and parameters.
 */
function generatePath(path = '/', params = {}) {
  try {
    return path === '/' ? path : compilePath(path)(params, { pretty: true });
  } catch (err) {
    return path
  }
}

export default generatePath;
