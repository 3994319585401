import { IconButton, InputAdornment } from '@mui/material';
import {
  FieldPath,
  FieldValues,
  UseControllerProps,
  useController,
} from 'react-hook-form';
import { useToggle } from 'react-use';
import InvisibleIcon from 'src/assets/icons/visibility-off.svg';
import VisibleIcon from 'src/assets/icons/visibility.svg';
import { MyInput, MyInputProps } from 'src/components/core/mine/my-input';

interface RHFMyInputProps<
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>,
> extends MyInputProps {
  controller: UseControllerProps<TFieldValues, TName>;
  errorFirst?: boolean;
}

export const RHFMyInput = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>(
  props: RHFMyInputProps<TFieldValues, TName>,
) => {
  const { controller, required, helperText, error, errorFirst, ...rest } =
    props;

  const {
    field: { ref, ...field },
    fieldState,
  } = useController(controller);

  const _err = error ?? Boolean(fieldState.error);

  const errMsg = fieldState.error?.message?.toString();
  const _helperText = errorFirst ? errMsg ?? helperText : helperText ?? errMsg;

  const _required = required ?? Boolean(controller.rules?.required);

  return (
    <MyInput
      {...rest}
      error={_err}
      required={_required}
      helperText={_helperText}
      {...field}
      inputRef={ref}
      onBlur={(e) => {
        field.onBlur();
        props.onBlur?.(e);
      }}
      onChange={(e) => {
        field.onChange(e);
        props.onChange?.(e);
      }}
      value={field.value || ''}
    />
  );
};

export const RHFMyPw = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>(
  props: RHFMyInputProps<TFieldValues, TName>,
) => {
  const [show, toggle] = useToggle(false);

  return (
    <RHFMyInput
      tabIndex={-1}
      {...props}
      type={show ? 'text' : 'password'}
      endAdornment={
        <InputAdornment tabIndex={-1} position="end" sx={{ ml: 0 }}>
          <IconButton
            edge="end"
            size="large"
            sx={{ color: 'cuscom.content.tertiary' }}
            color="inherit"
            onClick={toggle}>
            {show ? <VisibleIcon /> : <InvisibleIcon />}
          </IconButton>
        </InputAdornment>
      }
    />
  );
};
