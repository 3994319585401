import {
  Button,
  ButtonProps,
  CircularProgress,
  CircularProgressProps,
} from '@mui/material';
import React from 'react';
import { makeStyles } from 'tss-react/mui';

interface LoadingButtonProps extends ButtonProps {
  loading?: boolean;
  spinnerProps?: CircularProgressProps;
}

const useStyles = makeStyles()((theme) => {
  return {
    focused: { background: theme.palette.primary.dark },
  };
});

export const LoadingButton: React.FCC<LoadingButtonProps> = ({
  loading,
  spinnerProps,
  onClick,
  ...rest
}) => {
  const { classes, cx } = useStyles();
  return (
    <Button
      startIcon={
        loading ? (
          <CircularProgress color="inherit" size={16} {...spinnerProps} />
        ) : null
      }
      onClick={(e) => {
        if (loading) {
          return;
        }
        onClick?.(e);
      }}
      classes={{
        focusVisible: classes.focused,
      }}
      {...rest}
    />
  );
};
