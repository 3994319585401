import React from 'react';
import RECAPTCHA, { ReCAPTCHA, ReCAPTCHAProps } from 'react-google-recaptcha';
import { useLocale } from 'src/hooks/use-locale';

interface AppRecaptchaProps extends Omit<ReCAPTCHAProps, 'sitekey'> {}
const key = process.env.NEXT_PUBLIC_CAPTCHA_KEY ?? '';
export const AppRecaptcha = React.forwardRef<
  ReCAPTCHA | null,
  AppRecaptchaProps
>((props, ref) => {
  const { locale } = useLocale();
  return (
    <RECAPTCHA
      {...props}
      sitekey={key}
      theme="dark"
      ref={ref}
      hl={locale}
      size="invisible"
      badge="bottomleft"
    />
  );
});
AppRecaptcha.displayName = 'AppRecaptcha';
