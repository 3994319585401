import React from 'react';
import { makeStyles } from 'tss-react/mui';
interface AspectRatioProps {
  wrapperClassName?: string;
  className?: string;
  ratio?: number;
  disableWrapped?: boolean;
  component?: any;
}

// TODO jss-to-tss-react codemod: Unable to handle style definition reliably. Unsupported arrow function syntax.
// Arrow function has body type of BlockStatement instead of ObjectExpression.
const useStyles = makeStyles<{ ratio: number }>()((theme, { ratio }) => ({
  wrapper: {
    position: 'relative',
    paddingTop: `${ratio * 100}%`,
  },
  wrapped: {
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
  },

  absoluteAll: {
    '& > *': {
      position: 'absolute',
      top: 0,
      left: 0,
      bottom: 0,
      right: 0,
    },
  },
}));

export const AspectRatio: React.FCC<AspectRatioProps> = ({
  children,
  wrapperClassName,
  className,
  ratio = 9 / 16,
  disableWrapped = false,
  component = 'div',
  ...props
}) => {
  const { classes, cx } = useStyles({ ratio });

  return React.createElement(
    component,
    {
      className: cx(
        classes.wrapper,
        disableWrapped && classes.absoluteAll,
        wrapperClassName,
      ),
      ...props,
    },
    disableWrapped ? (
      children
    ) : (
      <div className={cx(classes.wrapped, className)}>{children}</div>
    ),
  );
};
