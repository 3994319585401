import {
  FormControl,
  FormHelperText,
  FormLabel,
  MenuItem,
  Select,
  type BaseSelectProps,
  type FormControlProps,
  type FormHelperTextProps,
  type FormLabelProps,
} from '@mui/material';
import React from 'react';

interface ObjWithValue {
  label: React.ReactNode;
  value: any;
}
export interface MySelectProps extends BaseSelectProps {
  FormLabelProps?: FormLabelProps;
  FormControlProps?: FormControlProps;
  FormHelperTextProps?: FormHelperTextProps;
  label?: React.ReactNode;
  helperText?: React.ReactNode;
  pattern?: RegExp | ((txt: string) => boolean);
  options?: ObjWithValue[];
}

export const MySelect = React.forwardRef<any, MySelectProps>(
  function MySelect(props, ref) {
    const {
      error,
      label,
      required,
      fullWidth,
      helperText,
      pattern,
      FormLabelProps,
      FormControlProps,
      FormHelperTextProps,
      inputRef,
      options,
      children,
      ...rest
    } = props;

    return (
      <FormControl
        {...FormControlProps}
        fullWidth={fullWidth}
        error={error}
        ref={ref}
        required={required}>
        {label && (
          <FormLabel
            {...FormLabelProps}
            sx={{
              ...FormLabelProps?.sx,
              textAlign: 'start',
            }}>
            {label}
          </FormLabel>
        )}
        <Select
          {...rest}
          MenuProps={{
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'left',
            },
            transformOrigin: {
              vertical: 'top',
              horizontal: 'left',
            },
            componentsProps: {
              backdrop: {
                onTouchMove: rest.onClose,
                invisible: true,
              },
            },
            disableScrollLock: false,
            disablePortal: true,
            ...rest.MenuProps,

            slotProps: {
              paper: {
                variant: 'outlined',
                sx: { mt: 0.5 },
              },
            },

            // classes: {
            //   paper: cx(MenuProps?.classes?.paper, styles.menuPaper),
            //   list: cx(MenuProps?.classes?.list, styles.menuList),
            // },
          }}
          inputRef={inputRef}>
          {options?.map((x) => {
            const val = x.value;

            return (
              <MenuItem key={val} value={val}>
                {x.label}
              </MenuItem>
            );
          })}
          {children}
        </Select>
        {helperText && (
          <FormHelperText component="div" {...FormHelperTextProps}>
            {helperText}
          </FormHelperText>
        )}
      </FormControl>
    );
  },
);
