/* eslint-disable react-hooks/exhaustive-deps */
import { Plural, Trans } from '@lingui/macro';
import {
  Button,
  CircularProgress,
  DialogTitle,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { useAtomValue } from 'jotai';
import React, { useEffect } from 'react';
import { MobileDialogBet } from 'src/_pages/home/dialog-bet/components/mobile-dialog-bet';
import { RowDialogBet } from 'src/_pages/home/dialog-bet/components/row-dialog-bet';
import ArrowDownIcon from 'src/assets/icons/arrow-down.svg';
import { BaseDialog } from 'src/components/core/base-dialog';
import { CopyToClipBoard } from 'src/components/core/clipboard';
import { useLocale, useMyRouter } from 'src/hooks/use-locale';
import { useOddsFormat } from 'src/hooks/use-odds-format';
import { useQuery } from 'src/hooks/use-query';
import { logoAtom } from 'src/store/contentful';
import { CRYPTO_DP } from 'src/utils/constants/common-constant';
import { getIconSrc } from 'src/utils/constants/icon-coins';
import {
  formatPayout,
  showBetStatusName,
  showBetStatusStyle,
  showStatusPayout,
} from 'src/utils/helpers/format-bet';
import { displayUsername } from 'src/utils/helpers/i18n';
import { convertTime } from 'src/utils/helpers/time';
import { RoomType } from 'src/utils/types/socket-type';
import { useStyles } from './styles';
import { useBetFetcher } from './use-bet-fetcher';
import BigNumber from 'bignumber.js';
import TokenAmount from 'src/components/core/token-amount';
export const DetailDialog: React.FCC = (props) => {
  const { classes, cx } = useStyles();
  const logo = useAtomValue(logoAtom);
  const { iid = '' } = useQuery(['iid']);
  const router = useMyRouter();
  const { bets, meta, loading, retry, reset, getAllBet } = useBetFetcher(iid);

  const odds = useOddsFormat(meta?.odds || '0');
  const { decimalSeparator } = useLocale();

  const open = Boolean(iid);

  useEffect(() => {
    if (!open) {
      reset();
    }
  }, [open]);

  const isMulti = meta?.type === 'MULTI';

  const icon = (
    <img
      src={getIconSrc(meta.roomId)}
      alt={meta.roomId}
      width={20}
      height={20}
    />
  );

  const count = isMulti ? meta.total_count : 1;

  const formatted = formatPayout(meta.payout, meta.status);

  const onClose = () => {
    router.push(router.pathname, { query: { iid: [] }, scroll: false });
  };

  return (
    <BaseDialog
      showCloseIcon
      closeIconProps={{ className: classes.iconClose }}
      maxWidth="md"
      open={open}
      onClose={onClose}>
      <DialogTitle className={classes.dialogTitle}>
        <Typography
          variant="caption"
          className={classes.status}
          style={showBetStatusStyle(meta.status)}>
          {showBetStatusName(meta.status)}
        </Typography>
        {meta?.roomType === RoomType.PROMOTION_CAMPAIGN && (
          <Typography variant="caption" className={classes.colorGreen}>
            {meta?.roomType}
          </Typography>
        )}
        <Typography className={classes.title}>
          <Plural
            id="sportsbook.latest_bets.bet_count"
            value={count}
            one="# Bet"
            other="# Bets"
          />
        </Typography>

        <Typography color="custom.content.2">
          <Trans id="sportsbook.latest_bets.bettor">
            by {displayUsername(meta?.username)}
          </Trans>
        </Typography>
      </DialogTitle>

      {loading ? (
        <div className={classes.loading}>
          <CircularProgress size={45} />
        </div>
      ) : bets ? (
        <>
          <TableContainer className={classes.tableContainer}>
            <Table className={classes.table}>
              <TableHead>
                <TableRow className={classes.head}>
                  <TableCell colSpan={5}>
                    <Trans id="sportsbook.match_info">Match Info</Trans>
                  </TableCell>
                  <TableCell colSpan={2}>
                    <Trans id="sportsbook.selection">Selection</Trans>
                  </TableCell>
                  <TableCell colSpan={2}>
                    <Trans id="sportsbook.odds">Odds</Trans>
                  </TableCell>
                  <TableCell>
                    <Trans id="common.status">Status</Trans>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {bets.map((item, index) => (
                  <RowDialogBet key={index} data={item} />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <div className={classes.boxMobileContainer}>
            {bets.map((item, index) => (
              <MobileDialogBet key={index} data={item} />
            ))}
          </div>
        </>
      ) : (
        open && (
          <div className={classes.loading}>
            <Trans id="error.something_went_wrong">
              Something went wrong. Please try again.
            </Trans>
            <Button
              className={classes.buttonTryAgain}
              variant="outlined"
              onClick={retry}>
              <Trans id="common.try_again">Try Again</Trans>
            </Button>
          </div>
        )
      )}
      {meta.page_count > 1 && (
        <div className={classes.showMore}>
          <Button
            className={classes.button}
            variant="text"
            color="inherit"
            onClick={getAllBet}>
            <Plural
              value={meta?.total_count - 6}
              id="common.show_more"
              one="Show More"
              other="Show More (#)"
            />
            <ArrowDownIcon className={classes.ml8} />
          </Button>
        </div>
      )}
      <div className={classes.footer}>
        <div>
          <Typography className={classes.fontWeightBold}>
            {isMulti ? (
              <Trans id="sportsbook.total_odds">Total Odds</Trans>
            ) : (
              <Trans id="sportsbook.odds">Odds</Trans>
            )}
          </Typography>
          <Typography className={classes.fontWeightBold}>{odds}</Typography>
        </div>
        <div>
          <Typography className={classes.fontWeightBold}>
            <Trans id="sportsbook.total_stakes">Total Stakes</Trans>
          </Typography>
          <div className={classes.flexCoin}>
            <Typography color="primary">
              <TokenAmount
                amount={meta?.total_stake}
                symbol={meta.roomId}
                roundingMode={BigNumber.ROUND_DOWN}
                decimal={CRYPTO_DP}
              />
            </Typography>
            {icon}
          </div>
        </div>
        <div>
          <Typography className={classes.fontWeightBold}>
            {showStatusPayout(meta.status)}
          </Typography>
          <div className={classes.flexCoin}>
            <Typography color={+meta.payout < 0 ? 'error' : 'primary'}>
              <TokenAmount
                amount={formatted.payout}
                symbol={meta.roomId}
                roundingMode={BigNumber.ROUND_DOWN}
                decimal={CRYPTO_DP}
              />
            </Typography>
            {icon}
          </div>
        </div>
      </div>
      <Typography
        component="div"
        color="custom.content.2"
        className={classes.address}>
        <img src={logo} alt="" height={16} />
        <span className="ticket-id">{iid}</span>
        <CopyToClipBoard getMsg={iid} />

        <div className="gap" />

        <span className="placed-at">
          <Trans id="sportsbook.ticket.placed_on">
            Placed on: {convertTime(meta.createdAt)}
          </Trans>
        </span>
      </Typography>
    </BaseDialog>
  );
};
