import { useAtomValue, useSetAtom } from 'jotai';
import dynamic from 'next/dynamic';
import { useSearchParams } from 'next/navigation';
import { useEffect } from 'react';
import { loggedAtom } from 'src/store/auth';
import { loginAtom, registerAtom } from 'src/store/layout';

const LoginDialog = dynamic(
  () => import('src/_pages/auth/login').then((mod) => mod.Login),
  { ssr: false },
);
const RegisterDialog = dynamic(
  () => import('src/_pages/auth/register').then((mod) => mod.RegisterDialog),
  { ssr: false },
);

const RegisterAndLogin: React.FC = () => {
  const params = useSearchParams();
  const setLogin = useSetAtom(loginAtom);
  const setRegister = useSetAtom(registerAtom);

  useEffect(() => {
    if (params.has('login')) {
      setLogin(true);
    } else if (params.has('register')) {
      setRegister(true);
    }
  }, [params.toString()]);

  return (
    <>
      <LoginDialog />
      <RegisterDialog />
    </>
  );
};

const BetSlipDrawer = dynamic(
  () =>
    import('src/components/modules/bet-slip-drawer').then(
      (mod) => mod.BetSlipDrawer,
    ),
  { ssr: false },
);
const MyAccountDrawer = dynamic(
  () =>
    import('src/components/modules/my-account-drawer').then(
      (r) => r.MyAccountDrawer,
    ),
  { ssr: false },
);
const MyMoneyDrawer = dynamic(
  () =>
    import('src/components/modules/my-money-drawer').then(
      (r) => r.MyMoneyDrawer,
    ),
  { ssr: false },
);

const Drawers: React.FC = () => {
  return (
    <>
      <BetSlipDrawer />
      <MyAccountDrawer />
      <MyMoneyDrawer />
    </>
  );
};

export const DrawersOrLogin: React.FC = () => {
  const logged = useAtomValue(loggedAtom);

  if (logged) {
    return <Drawers />;
  }

  return <RegisterAndLogin />;
};
