import { MenuItem } from '@mui/material';
import { useAtom } from 'jotai';
import { MySelect, MySelectProps } from 'src/components/core/mine/my-select';
import { oddsFormatAtom } from 'src/store/layout';
import { getOddFormats } from 'src/utils/constants/common-constant';

export const OddsFormatSelect: React.FC<MySelectProps> = (props) => {
  const [oddsFormat, setOddsFormat] = useAtom(oddsFormatAtom);

  return (
    <MySelect value={oddsFormat}>
      {getOddFormats().map(({ label, value }) => {
        return (
          <MenuItem
            value={value}
            key={value}
            onClick={() => setOddsFormat(value)}>
            {label}
          </MenuItem>
        );
      })}
    </MySelect>
  );
};
