import { Trans } from '@lingui/macro';
import _ from 'lodash';
import { useSnackbar } from 'notistack';
import { useEffect } from 'react';
import { getGamesByCategory } from 'src/api/casino';
import { getGamesByCategoryKey } from 'src/utils/helpers/swr';
import { CasinoGame, GetGamesParams } from 'src/utils/types/casino';
import { IPagination } from 'src/utils/types/response';
import useSWRInfinite, {
  SWRInfiniteConfiguration,
  SWRInfiniteResponse,
} from 'swr/infinite';

type UseGameOpts = {
  enable?: boolean;
  fetcher?: typeof getGamesByCategory;
  toast?: boolean;
} & SWRInfiniteConfiguration<IPagination<CasinoGame>>;

export interface UseGameReturns
  extends SWRInfiniteResponse<IPagination<CasinoGame, any>, any> {
  hasMore: boolean;
  loadMore: () => Promise<IPagination<CasinoGame, any>[] | undefined>;
  totalCount: number;
}

const notiKey = 'games_not_accessible';
export const useSwrCasinoGames = (
  params: Partial<GetGamesParams>,
  _opts?: UseGameOpts,
): UseGameReturns => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const { fetcher, toast = false, ...withoutFetcher } = _opts ?? {};
  const opts = {
    ...withoutFetcher,
    enable: withoutFetcher?.enable ?? true,
  };

  const swr = useSWRInfinite(
    opts.enable ? getGamesByCategoryKey(params) : _.constant(null),
    async ([, params]) => {
      return (fetcher ?? getGamesByCategory)(params);
    },
    { revalidateFirstPage: false, revalidateAll: false, ...opts },
  );

  const { data, setSize } = swr;

  const { totalCount, hasMore } = swrHasMore(data);
  const loadMore = () => setSize((size) => size + 1);

  const inaccessible =
    toast && // enable
    !fetcher && // not custom fetcher
    !!data && // fetched successful
    _.isEmpty(params.producer_in) && // not filter
    _.isEmpty(params.theme_in) && // not filter
    _.isEmpty(params.__features) && // not filter
    totalCount <= 0;

  useEffect(() => {
    if (!inaccessible) return;
    const timer = setTimeout(() => {
      enqueueSnackbar(
        <Trans id="games_not_accessible">
          Games are available for play, but are not accessible in your current
          location
        </Trans>,
        {
          variant: 'error',
          autoHideDuration: null,
          hideIconVariant: true,
          preventDuplicate: true,
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'center',
          },
          key: notiKey,
        },
      );
    }, 1000);
    return () => {
      closeSnackbar(notiKey);
      clearTimeout(timer);
    };
  }, [inaccessible]);

  return {
    hasMore,
    loadMore,
    totalCount,
    ...swr,
  };
};

export function swrHasMore<T>(data: IPagination<T>[] | undefined) {
  const totalCount = _.first(data)?.meta?.total_count ?? 0;
  let current_count = 0;

  for (const i of data ?? []) {
    if (i) {
      current_count += i?.items?.length ?? 0;
    }
  }

  const hasMore = current_count < totalCount;
  return { hasMore, totalCount };
}
