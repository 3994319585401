import { Trans } from '@lingui/macro';
import { Box, Typography } from '@mui/material';
import InfoSvg from 'src/assets/icons/info.svg';

interface CanceledProps {}

export const Canceled: React.FC<CanceledProps> = (props) => {
  return (
    <>
      <Box flexShrink={0} component={InfoSvg} width={24} height={24} />

      <Typography sx={{ fontSize: 13, ml: 1 }}>
        <Trans id="multi_bet.canceled">
          1 or more of your bets has been suspended or canceled{' '}
          <u>please check your Bet Slip</u>.
        </Trans>
      </Typography>
    </>
  );
};
