import { Box, BoxProps } from '@mui/material';
import { useAtomValue } from 'jotai';
import Image from 'next/image';
import ChatIcon from 'src/assets/icons/chat.svg';
import { MyLink } from 'src/components/core/mine/my-link';
import { socialMediasAtom } from 'src/store/contentful';
import { showIntercom } from 'src/utils/helpers/intercom';
import { makeStyles } from 'tss-react/mui';
type SocialsProps = {
  containerProps?: BoxProps;
};

const useStyles = makeStyles()((theme) => ({
  socialMediaLink: {
    marginRight: '18px',
    minWidth: '32px !important',
    width: '32px !important',
    height: '32px',
    borderRadius: '4px',
    padding: '0 !important',
    boxSizing: 'border-box',
    backgroundColor: theme.custom.bg[2],
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    border: 0,
    cursor: 'pointer',
  },
}));
const Socials = ({ containerProps }: SocialsProps) => {
  const socialMedia = useAtomValue(socialMediasAtom);
  const { classes, cx } = useStyles();
  return (
    <Box sx={{ display: 'flex' }} {...containerProps}>
      <button
        className={classes.socialMediaLink}
        onClick={() => {
          showIntercom();
        }}>
        <ChatIcon />
      </button>
      {socialMedia.map((social) => {
        const { title, link, image } = social;
        return (
          <MyLink
            key={link}
            color="inherit"
            className={classes.socialMediaLink}
            target="_blank"
            href={link ?? ''}>
            <Image
              src={image?.url ?? '/null'}
              alt={title ?? ''}
              width={16}
              height={16}
            />
          </MyLink>
        );
      })}
    </Box>
  );
};

export default Socials;
