import {
  BottomNavigation,
  BottomNavigationAction,
  Box,
  Container,
} from '@mui/material';
import React from 'react';
import { MyLink } from 'src/components/core/mine/my-link';
import { useNavbarTabs } from '..';
import { useStyles } from './styles';

interface BottomNavProps {}

export const BottomNav: React.FCC<BottomNavProps> = (props) => {
  const { classes, cx } = useStyles();

  const { tabs, currentTab } = useNavbarTabs();

  return (
    <Box className={classes.hiddenOnMobile}>
      <Container className={classes.container}>
        <BottomNavigation
          value={currentTab}
          sx={{
            '& .Mui-selected': {
              color: 'primary.main',
              '& *': { fill: 'currentcolor' },
            },
          }}
          showLabels
          className={classes.main}
          style={{ backgroundColor: 'inherit' }}>
          {tabs.map(({ href, label, icon }, i) => {
            return (
              <BottomNavigationAction
                noLinkStyle
                component={MyLink}
                href={href}
                classes={{ selected: classes.selected }}
                value={href}
                className={classes.item}
                style={{ flex: 1 }}
                icon={icon && React.createElement(icon)}
                key={i}
                label={label}
              />
            );
          })}
        </BottomNavigation>
      </Container>
    </Box>
  );
};
