import { Stack } from '@mui/material';
import { atom, useAtomValue } from 'jotai';
import { useEffect } from 'react';
import { useDrawerVariant } from 'src/components/modules/user-drawer';
import { loggedAtom } from 'src/store/auth';
import { rightDrawerAtom } from 'src/store/bet-slip';
import { DESKTOP_BS_WIDTH } from 'src/utils/constants/keys';
import { updateConfigIntercom } from 'src/utils/helpers/intercom';

const openAnyDrawerAtom = atom((get) => {
  return get(loggedAtom) && !!get(rightDrawerAtom);
});

const useContentShift = () => {
  const openDrawer = useAtomValue(openAnyDrawerAtom);
  const { shift } = useDrawerVariant();

  const shifted = openDrawer && shift;

  useEffect(() => {
    updateConfigIntercom({
      horizontal_padding: (shifted ? DESKTOP_BS_WIDTH : 0) + 20,
    });
  }, [shifted]);

  return shifted;
};

export const Main: React.FCC = ({ children }) => {
  const shift = useContentShift();

  return (
    <Stack
      sx={{
        flexGrow: 1,
        transition: (theme) =>
          theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
          }),
        mr: 0,
        width: '100%',

        '&.shift': {
          transition: (theme) =>
            theme.transitions.create(['margin', 'width'], {
              easing: theme.transitions.easing.easeOut,
              duration: theme.transitions.duration.enteringScreen,
            }),
          width: `calc(100% - ${DESKTOP_BS_WIDTH}px)`,
          marginRight: DESKTOP_BS_WIDTH,
        },
      }}
      className={shift ? 'shift' : undefined}>
      {children}
    </Stack>
  );
};
