import { iconButtonClasses } from '@mui/material/IconButton';
import { tableCellClasses } from '@mui/material/TableCell';
import { QueryMapping } from 'src/hooks/use-breakpoint';
import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme) => {
  const mobile = QueryMapping.mobile;

  return {
    iconClose: {
      top: '6.5px !important',
      right: '4px !important',
    },
    dialogTitle: {
      padding: theme.spacing(2),
      display: 'flex',
      alignItems: 'center',
      borderBottom: `1px solid ${theme.palette.divider}`,
      minHeight: 21 + 16 + 16,

      '& > *': {
        marginRight: theme.spacing(1),
      },
      [mobile]: {
        borderBottom: 'none',
      },
    },
    title: {
      fontWeight: 'bold',
      fontSize: 16,
    },
    tableContainer: {
      [mobile]: {
        display: 'none',
      },
    },
    boxMobileContainer: {
      display: 'none',
      [mobile]: {
        display: 'block',
        borderBottom: `1px solid ${theme.palette.divider}`,
      },
    },
    table: {
      borderBottom: `1px solid ${theme.palette.divider}`,
    },
    head: {
      [`& .${tableCellClasses.root}`]: {
        padding: theme.spacing(1.5, 2),
        boxSizing: 'border-box',
      },
    },
    status: {
      fontWeight: 'bold',
      fontSize: 12,
      textTransform: 'uppercase',
      padding: theme.spacing(0.375, 0.625),
      borderRadius: 3,
      lineHeight: 'normal',
      width: 'fit-content',
      letterSpacing: 0.4,
    },
    fontWeightBold: {
      fontWeight: 'bold',
    },
    loading: {
      height: 142,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'column',
    },
    footer: {
      padding: theme.spacing(4, 2, 5),
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',

      '& > div': {
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: theme.spacing(1.75),
        maxWidth: 400,
        width: '100%',
        [QueryMapping.mobile]: {
          maxWidth: '100%',
        },
        '&:last-child': {
          marginBottom: 0,
        },
      },
    },
    flexCoin: {
      display: 'flex',
      '& > img': {
        marginLeft: theme.spacing(0.5),
      },
    },
    font16: {
      fontSize: 16,
    },
    buttonTryAgain: {
      marginTop: theme.spacing(3),
    },
    round: {
      padding: theme.spacing(1.5, 2),
      borderBottom: `1px solid ${theme.palette.divider}`,
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      [mobile]: {
        borderBottom: 'none',
      },
    },
    divider: {
      backgroundColor: theme.palette.divider,
      height: 1,
    },
    iconCopy: {
      display: 'flex',
      alignItems: 'center',
      maxHeight: 18,
      [`& > .${iconButtonClasses.root}`]: { marginLeft: -4 },
    },
    colorGreen: {
      color: theme.custom.bg[1],
      backgroundColor: '#E5B122',
      fontWeight: 700,
      padding: '3px 5px',
      borderRadius: 3,
      fontSize: 12,
    },
    button: {
      color: theme.custom.content[1],
      fontWeight: 500,
      fontSize: 14,
    },
    showMore: {
      display: 'flex',
      justifyContent: 'center',
      width: '100%',
      marginTop: 16,
    },
    ml8: {
      marginLeft: 8,
    },
    address: {
      display: 'flex',
      alignItems: 'center',
      boxShadow: '8px 0 8px rgba(0, 0, 0, 0.16)',
      padding: '12px 16px',
      [mobile]: {
        padding: '12px 8px',
      },

      '& > .ticket-id': {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      },

      '& > img': {
        flexShrink: 0,
        marginRight: 12,
        [mobile]: {
          marginRight: 4,
        },
      },

      '& > .gap': { flexGrow: 1 },

      '& > .icon': { padding: 4 },

      '& > .placed-at': {
        marginLeft: 16,
        flexShrink: 0,
        [mobile]: {
          marginLeft: 12,
        },
      },
    },
  };
});
