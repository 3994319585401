import { keyframes, styled } from '@mui/material';
import BigNumber from 'bignumber.js';
import _ from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { usePreviousDistinct } from 'react-use';
import Triangle from 'src/assets/icons/triangle.svg';
import { BN } from 'src/utils/helpers/big-number';

const upAnimation = keyframes`
  0%,20% {
    transform: translateY(20%);
    opacity: 1;
  }

  to {
    transform: translateY(-20%);
    opacity: 0;
  }
`;

const downAnimation = keyframes`
  0%,20% {
    transform: translateY(-20%) rotate(-180deg);
    opacity: 1;
  }

  to {
    transform: translateY(20%) rotate(-180deg);
    opacity: 0;
  }
`;

const interval = 3200;
const count = 4;

const StyledTriangle = styled(Triangle, {
  shouldForwardProp: (prop) => prop !== 'diff',
})<{
  diff?: number | null;
}>(({ theme, diff }) => {
  return {
    animationDuration: `${interval / count}ms`,
    animationIterationCount: count,
    animationFillMode: 'forwards',
    ...(diff && {
      display: 'inline-block',
      color: diff === 1 ? theme.custom.status.green : theme.custom.status.red,
      animationName: diff === 1 ? upAnimation : downAnimation,
    }),
  };
});

export const OddsArrow: React.FC<{
  odds?: BigNumber.Value;
  className?: string;
}> = ({ odds, className }) => {
  const prevOdds = usePreviousDistinct(odds, (a, b) => a == b);
  const timeout = useRef<ReturnType<typeof setTimeout>>();
  const [diff, setDiff] = useState<number | null>();

  useEffect(() => {
    if (_.isNil(odds) || _.isNil(prevOdds)) return;

    clearTimeout(timeout.current);

    const diff = new BN(odds).comparedTo(prevOdds);
    setDiff(diff || null);

    const token = setTimeout(() => {
      setDiff(null);
    }, interval);
    timeout.current = token;

    return () => {
      clearTimeout(token);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [odds, prevOdds]);

  if (!diff) return null;

  return <StyledTriangle className={className} diff={diff} />;
};
