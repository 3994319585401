import { useAtom } from 'jotai';
import _ from 'lodash';
import { useEffect } from 'react';
import { SocketEvent } from 'src/utils/constants/socket-event';
import { isOddin, mappingOddinToBr } from 'src/utils/helpers/fixture';
import { BaseMessage } from 'src/utils/types/socket-type';
import { useStableFn } from '../use-stable-fn';
import { socketAtom } from './use-socket';

const cached = new Map<string, number>();

function update(key: string, delta: number) {
  const prev = cached.get(key) ?? 0;
  const next = Math.max(prev + delta, 0);
  cached.set(key, next);
  return next;
}

/**
 * @param forEvent SocketEvent.ODD_CHANGE_V5
 * @param roomId The fixture id
 * @param callback the called func when client receives @param forEvent
 * @param enabled join room and listen to @param forEvent when this is true
 *
 * ```ts
 *
 * useRoom(SocketEvent.ODD_CHANGE_V5, 'sr:match:1', () => {
 *    your code...
 * })
 *
 * ```
 */
export const useRoom = <T extends SocketEvent>(
  forEvent: T,
  roomId: string | string[] | undefined,
  callback: (msg: BaseMessage<T>) => any,
  enabled = true,
) => {
  const [socket, registerListener] = useAtom(socketAtom);

  const _roomIds = typeof roomId === 'string' ? [roomId] : roomId ?? [];

  const isReady = enabled && socket && !_.isEmpty(_roomIds);

  const handler = useStableFn(callback);

  useEffect(() => {
    if (!socket?.id) {
      for (const i of _roomIds) {
        cached.set(i, 0);
      }
    }
  }, [socket?.id]);

  const rooms = _roomIds.join('');

  useEffect(() => {
    if (!isReady) return;

    for (const k of _roomIds) {
      const next = update(k, 1);

      if (next === 1) {
        socket.emit('joinRoom', k);
      }
    }

    const cleanup = registerListener(forEvent, (msg: BaseMessage<T>) => {
      if (!_roomIds?.includes(msg.room)) return;

      if (forEvent === SocketEvent.FIXTURE_CHANGE_V5 && 'fixture_info' in msg) {
        // @ts-ignore
        _.set(msg, ['fixture_refined'], refineBrFixture(msg.fixture_info));
      }

      handler({
        ...msg,
        message: {
          ...msg.message,
          _product: isOddin(msg.room)
            ? mappingOddinToBr(msg.message._product)
            : msg.message._product,
        },
      });
    });

    return () => {
      cleanup?.();

      for (const k of _roomIds ?? []) {
        const next = update(k, -1);
        if (next === 0) socket.emit('leaveRoom', k);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [registerListener, forEvent, isReady, rooms]);
};
