import { Trans } from '@lingui/macro';
import { MenuItem } from '@mui/material';
import { MySelect, MySelectProps } from 'src/components/core/mine/my-select';
import { useLocale } from 'src/hooks/use-locale';

const getLocaleList = () => {
  return [
    {
      code: 'en',
      name: <Trans id="left_nav.languages.en">English</Trans>,
    },
    {
      code: 'pt-br',
      name: <Trans id="left_nav.languages.pt-br">Brazilian Portugues</Trans>,
    },
    {
      code: 'fr-ca',
      name: <Trans id="left_nav.languages.fr-ca">Français Canadien</Trans>,
    },
  ];
};

export const LocaleSelect: React.FC<MySelectProps> = (props) => {
  const locales = getLocaleList();
  const { locale, onLocaleChange } = useLocale();

  return (
    <MySelect
      {...props}
      value={locale}
      // renderValue={(value: any) => {
      //   return (
      //     <Stack spacing={1.5} direction="row" alignItems="center">
      //       <LocaleIcon />
      //       <span>{_.find(locales, { code: value })?.name}</span>
      //     </Stack>
      //   );
      // }}
      onChange={(e) => {
        const locale = String(e.target.value);
        onLocaleChange(locale);
      }}>
      {locales.map((o) => {
        return (
          <MenuItem key={o.code} value={o.code}>
            {o.name}
          </MenuItem>
        );
      })}
    </MySelect>
  );
};
