import _ from 'lodash';
import type { PromiseType } from 'react-use/lib/misc/types';
import type { getCampaigns } from 'src/api/campaign';
import type {
  fetchOneGame,
  getAllThemes,
  getGamesByCategory,
  getMyBetCasino,
  getProducersUser,
} from 'src/api/casino';
import type { getCashback } from 'src/api/offer';
import {
  getHotEvents,
  getTimeRange,
  type fetchCategories,
  type getDetailFixtureV2,
  type getDetailOutrightV2,
  type getFixtureList,
  type getOutrightSportV2,
} from 'src/api/sportbook-v2/event';
import type { fetchPopularMarkets } from 'src/api/sportbook-v2/sports';
import type { getActiveBoard, getMyBet } from 'src/api/sportbook-v2/ticket';
import type { getFixtureCount } from 'src/api/sports';
import type { getActiveBoardCasino } from 'src/api/ticket';
import type {
  getHotTournaments,
  getMyTourRank,
  getOneTournament,
  getTourLeaderboard,
  getTournamentCount,
  getTournaments,
} from 'src/api/tournament';
import type {
  fetchActiveNetworks,
  getAddressActiveOfWallet,
  getTxHistory,
} from 'src/api/wallet';

type Optional<T, K extends string> = Omit<T, K> & Partial<T>;

export type WithoutPagination<T> =
  | Optional<T, 'page_index' | 'page_size'>
  | Optional<T, 'page' | 'size'>
  | Optional<T, 'offset' | 'limit'>;

type WithPagination<T> =
  | (T & { page_index: number; page_size: number })
  | (T & { page: number; size: number })
  | (T & { offset: number; limit: number });

type WithoutPaginationArgs<T> = T extends (infer U)[]
  ? WithoutPagination<U>[]
  : T;
type WithPaginationArgs<T> = T extends (infer U)[] ? WithPagination<U>[] : T;

function genKey<
  Fn extends (...args: any[]) => any,
  Args extends Parameters<Fn> = Parameters<Fn>,
>(key: string): (...args: Args) => [string, ...Args] {
  return (...args) => [key, ...args];
}

function getInfiniteKey<
  Fn extends (...args: any) => any,
  Args extends Parameters<Fn> = Parameters<Fn>,
  Res extends PromiseType<ReturnType<Fn>> = PromiseType<ReturnType<Fn>>,
>(
  key: string,
  reachEnd: (prev: Res) => boolean,
  serializer: (
    page_index: number,
    prev: Res,
    ...args: Args
  ) => [...WithPaginationArgs<Args>],
): (
  ...args: WithoutPaginationArgs<Args>
) => (page_index: number, prev: Res) => null | [string, ...Args] {
  return (...args) =>
    // @ts-ignore
    (page_index, prev) => {
      if (reachEnd(prev)) return null;
      // @ts-ignore
      const dirtyArgs = serializer(page_index, prev, ...args);

      const cleanArgs = _.map(dirtyArgs, (a) => {
        return _.isPlainObject(a) ? _.omitBy(a, _.isNil) : a;
      }) as [...WithPaginationArgs<Args>];

      return [key, ...cleanArgs];
    };
}

export const getGamesByCategoryKey = getInfiniteKey<typeof getGamesByCategory>(
  'getGamesByCategory',
  (prev) => prev && !prev.items.length,
  (page_index, prev, params) => {
    return [
      {
        ...params,
        page_index,
        page_size: params.page_size ?? 25,
      },
    ];
  },
);

export const getTournamentsKey = getInfiniteKey<typeof getTournaments>(
  'getTournaments',
  (prev) => prev && !prev.items.length,
  (page_index, prev, params) => {
    const page_size = params.page_size ?? 12;
    return [{ ...params, page_index, page_size }];
  },
);

export const getFixtureCountKey =
  genKey<typeof getFixtureCount>('getFixtureCount');

export const fetchPopularMarketsKey = genKey<typeof fetchPopularMarkets>(
  'fetchPopularMarkets',
);

export const fetchOneGameKey = genKey<typeof fetchOneGame>('fetchOneGame');

export const getDetailOutrightV2Key = genKey<typeof getDetailOutrightV2>(
  'getDetailOutrightV2',
);

export const getDetailFixtureV2Key =
  genKey<typeof getDetailFixtureV2>('getDetailFixtureV2');

export const getOutrightSportV2Key =
  genKey<typeof getOutrightSportV2>('getOutrightSportV2');

export const fetchCategoriesKey =
  genKey<typeof fetchCategories>('fetchCategories');

export const getProducersUserKey =
  genKey<typeof getProducersUser>('getProducersUser');

export const getAllThemesKey = genKey<typeof getAllThemes>('getAllThemes');
export const getLatestBetKey = genKey<typeof getActiveBoard>('getActiveBoard');

export const getLatestBetCasinoKey = genKey<typeof getActiveBoardCasino>(
  'getActiveBoardCasino',
);

export const fetchActiveNetworksKey = genKey<typeof fetchActiveNetworks>(
  'fetchActiveNetworks',
);

export const getAddressActiveOfWalletKey = genKey<
  typeof getAddressActiveOfWallet
>('getAddressActiveOfWallet');

export const getMyBetKey = genKey<typeof getMyBet>('getMyBet');
export const getMyBetCasinoKey =
  genKey<typeof getMyBetCasino>('getMyBetCasino');
export const getCashbackKey = genKey<typeof getCashback>('getCashback');

export const getHotTournamentsKey =
  genKey<typeof getHotTournaments>('getHotTournaments');

export const getTournamentCountKey =
  genKey<typeof getTournamentCount>('getTournamentCount');

export const getOneTournamentKey =
  genKey<typeof getOneTournament>('getOneTournament');

export const getTourLeaderboardKey =
  genKey<typeof getTourLeaderboard>('getTourLeaderboard');
export const getMyTourRankKey = genKey<typeof getMyTourRank>('getMyTourRank');
export const getTxHistoryKey = genKey<typeof getTxHistory>('getTxHistory');

// V2
export const getFixtureListKey = getInfiniteKey<typeof getFixtureList>(
  'getFixtureList',
  (prev) => prev && !prev.items.length,
  (page_index, prev, params) => {
    const page_size = params.page_size ?? 10;
    return [
      {
        ...params,
        page_index,
        page_size,
        __ranges: getTimeRange(prev?.meta?.__ranges, prev),
      },
    ];
  },
);

export const getCampaignsKey = genKey<typeof getCampaigns>('getAllCampaigns');

export const getHotEventsKey = genKey<typeof getHotEvents>('getHotEvents');
