import { Trans } from '@lingui/macro';
import {
  AppBar,
  AppBarProps,
  Box,
  Button,
  Container,
  Tab,
  tabsClasses,
  Toolbar,
} from '@mui/material';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import React, { useMemo } from 'react';
import { useActiveBetCounter } from 'src/_pages/home/my-bets/hooks/use-fetch-my-bet';
import TournamentIcon from 'src/assets/icons/casino-config.svg';
import Casino from 'src/assets/icons/casino.svg';
import Esports from 'src/assets/icons/esports.svg';
import HomeIcon from 'src/assets/icons/home.svg';
import Sports from 'src/assets/icons/sports.svg';
import { Logo } from 'src/components/core/logo';
import { MyLink } from 'src/components/core/mine/my-link';
import { QueryMapping } from 'src/hooks/use-breakpoint';
import { useMyRouter } from 'src/hooks/use-locale';
import { userIdAtom } from 'src/store/auth';
import { betslipsLengthAtom, openBetSlipAtom } from 'src/store/bet-slip';
import { loginAtom, registerAtom } from 'src/store/layout';
import {
  DESKTOP_NAV_BAR_HEIGHT,
  MOBILE_NAV_BAR_HEIGHT,
} from 'src/utils/constants/keys';
import { Paths } from 'src/utils/constants/path';
import { isDeviceType, MyDevices } from 'src/utils/helpers/etc';
import {
  trackDepositInitiated,
  trackRegisterInit,
} from 'src/utils/helpers/rudderstack';
import { generatePath } from 'src/utils/libs/react-router-dom';
import matchPath from 'src/utils/libs/react-router-dom/matchPath';
import { SportTypeEnum } from 'src/utils/types/sport';
import { ScrollableTabs } from '../scrollable-tabs';
import { CurrentUser } from './current-user';
import { SearchBar } from './search-bar';
import { SeeBalance } from './see-balance';

const getPublicTabs = () => {
  const Home = {
    label: <Trans id="home">Home</Trans>,
    href: Paths.Home,
    path: [Paths.Home],
    icon: HomeIcon,
  };
  const Promotions = {
    label: <Trans id="promotions">Promotions</Trans>,
    href: Paths.Specials,
    path: [Paths.Promotion],
    icon: TournamentIcon,
  };

  return [
    Home,
    {
      label: <Trans id="casino">Casino</Trans>,
      href: Paths.LobbyBetUTopPicks,
      path: [generatePath(Paths.MetaCasino)],
      icon: Casino,
    },
    {
      label: <Trans id="sportsbook.sports">Sports</Trans>,
      href: generatePath(Paths.MetaLiveSport, {
        sportType: SportTypeEnum.Sport,
      }),
      path: [
        generatePath(Paths.MetaSport, {
          sportType: SportTypeEnum.Sport,
        }),
      ],
      icon: Sports,
    },
    {
      label: <Trans id="sportsbook.esports">Esports</Trans>,
      href: generatePath(Paths.MetaLiveSport, {
        sportType: SportTypeEnum.ESport,
      }),
      path: [
        generatePath(Paths.MetaSport, {
          sportType: SportTypeEnum.ESport,
        }),
      ],
      icon: Esports,
    },
    Promotions,
  ];
};

export const useNavbarTabs = () => {
  const tabs = useMemo(getPublicTabs, []);
  const { pathname } = useMyRouter();

  const currentTab = useMemo(() => {
    if (!pathname) return false;

    return tabs.findLast((x) => {
      const obj = matchPath(pathname, x.path);
      return !!obj;
    })?.href;
  }, [pathname, tabs]);

  return { tabs, currentTab };
};

export const NavBar: React.FCC<AppBarProps> = (props) => {
  const setLogin = useSetAtom(loginAtom);
  const setRegister = useSetAtom(registerAtom);

  const userId = useAtomValue(userIdAtom);
  const logged = !!userId;

  const activeBetsLength = useActiveBetCounter();

  const betSlipLength = useAtomValue(betslipsLengthAtom);

  const [openBetSlip, setOpenBetSlip] = useAtom(openBetSlipAtom);

  const { tabs, currentTab } = useNavbarTabs();

  const onDeposit = () => {
    trackDepositInitiated({
      userId,
      source: 'header',
    });
  };

  return (
    <AppBar
      position="sticky"
      color="transparent"
      square
      {...props}
      sx={{
        left: '0',
        bgcolor: 'common.black',
        transition: (theme) =>
          theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
          }),
        ...props?.sx,
      }}>
      <Toolbar variant="dense" sx={{ bgcolor: 'custom.header' }}>
        <Container
          sx={{
            display: 'flex',
            height: { xs: MOBILE_NAV_BAR_HEIGHT, sm: DESKTOP_NAV_BAR_HEIGHT },
            alignItems: 'center',
            ...(isDeviceType(MyDevices.MOBILE) && { overflowX: 'auto' }),
          }}>
          <Logo href={Paths.Home} sx={{ mr: { xs: 0, sm: 2 } }} />
          {/* @ts-ignore */}
          <ScrollableTabs
            sx={{
              minWidth: 150,
              [QueryMapping.mobile]: {
                display: 'none',
              },
              [`& .${tabsClasses.indicator}`]: {
                '& > span': {
                  bgcolor: 'primary.main',
                },
              },
            }}
            value={currentTab}>
            {tabs.map((item, index) => {
              const { href } = item;

              return (
                <Tab
                  component={MyLink}
                  value={href}
                  noLinkStyle
                  key={index}
                  href={href}
                  label={item.label}
                  sx={{
                    p: 0,
                    flex: '1 0 auto',
                    flexWrap: 'nowrap',
                    minWidth: 'auto !important',
                    '&:not(:last-child)': {
                      mr: 3,
                    },
                  }}
                />
              );
            })}
          </ScrollableTabs>
          <SearchBar />

          {logged && (
            <>
              <Button
                sx={{ mr: 1, [QueryMapping.mobile]: { display: 'none' } }}
                component={MyLink}
                href={Paths.Deposit}
                onClick={onDeposit}>
                <Trans id="deposit">Deposit</Trans>
              </Button>
              <CurrentUser />
              <SeeBalance />
            </>
          )}
          {logged ? (
            <Button
              onClick={() => setOpenBetSlip(!openBetSlip)}
              variant="outlined"
              sx={{
                p: 1,
                ml: 1.5,
                height: 38,
                borderRadius: '3px',
                display: 'flex',
                flexDirection: 'column',
                ...(!openBetSlip && {
                  borderColor: 'custom.content.3',
                  bgcolor: 'custom.bg.2',
                }),
                whiteSpace: 'nowrap',
                flexShrink: 0,
              }}>
              <Box component="span" sx={{ fontSize: 11, fontWeight: 700 }}>
                <Trans id="bets">Bets {betSlipLength}</Trans>
              </Box>
              <Box
                component="span"
                sx={{
                  fontSize: 10,
                  color: 'custom.content.2',
                  fontWeight: 400,
                }}>
                <Trans id="bets.active">Active {activeBetsLength}</Trans>
              </Box>
            </Button>
          ) : (
            <>
              <Button
                sx={{ mr: 1.5 }}
                color="inherit"
                variant="outlined"
                onClick={() => setLogin(true)}>
                <Trans id="auth.login">Login</Trans>
              </Button>
              <Button
                onClick={() => {
                  trackRegisterInit('header_button');
                  setRegister(true);
                }}
                color="primary"
                variant="contained">
                <Trans id="auth.register">Register</Trans>
              </Button>
            </>
          )}
        </Container>
      </Toolbar>
    </AppBar>
  );
};
