import { Trans } from '@lingui/macro';
import { Box, Container, Stack, Typography, Link } from '@mui/material';
import { useAtomValue } from 'jotai';
import Logo18 from 'src/assets/icons/icon-18+.svg?url';
import SSL from 'src/assets/icons/ssl.svg?url';
import { Logo } from 'src/components/core/logo';
import { MyLink } from 'src/components/core/mine/my-link';
import { QueryMapping } from 'src/hooks/use-breakpoint';
import { legalsAtom } from 'src/store/contentful';
import { TERMS_AND_CONDITIONS_TAB } from 'src/utils/constants/common-constant';
import { Paths } from 'src/utils/constants/path';
import { generatePath } from 'src/utils/libs/react-router-dom';
import { makeStyles } from 'tss-react/mui';
import { LocaleSelect } from '../locale-select';
import { OddsFormatSelect } from '../odds-format';
import Socials from '../socials';

const useStyles = makeStyles()((theme) => ({
  container: {
    [QueryMapping.mobile]: {
      paddingBottom: '100px !important',
    },
    backgroundColor: 'common.black',
    flexGrow: 1,
    padding: theme.spacing(3, 0),
  },
  logoContainer: {
    marginBottom: '20px',
    display: 'flex',
    alignItems: 'center',
  },

  over18Label: {
    marginLeft: '10px',
    fontSize: '11px !important',
    lineHeight: '11px !important',
  },
  over18LogoContainer: {
    display: 'flex',
    alignItems: 'center',
    marginRight: '20px',
    flexBasis: '120px',
  },
  curacaoLogo: {
    width: '100px',
    [QueryMapping.mobile]: {
      width: '68px',
    },
  },
  logo: {
    lineHeight: '0',
    '& .mobile-logo': {
      width: '55px',
    },
  },
}));

export const Footer: React.FC = () => {
  const { classes } = useStyles();

  const legals = useAtomValue(legalsAtom);

  const selects = (
    <>
      <Stack sx={{ flexShrink: 0, flexWrap: 'wrap' }}>
        <Typography sx={{ mb: '10px' }} fontWeight="bold">
          <Trans id="odds_format">Odds Format</Trans>
        </Typography>
        <OddsFormatSelect />
      </Stack>

      <Stack sx={{ flexShrink: 0, flexWrap: 'wrap' }}>
        <Typography sx={{ mb: '10px' }} fontWeight="bold">
          <Trans id="footer.languages">Languages</Trans>
        </Typography>
        <LocaleSelect />
      </Stack>
    </>
  );

  return (
    <Box sx={{ flexGrow: 0 }} className={classes.container}>
      <Container>
        <Box className={classes.logoContainer}>
          <Box
            sx={{
              display: 'flex',
              flexShrink: 0,
              flexDirection: 'column',
            }}>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Logo href="/" className={classes.logo} />
              <Typography
                sx={{ marginLeft: '10px', fontSize: '11px', flex: 1 }}>
                © UBET {new Date().getFullYear()}
              </Typography>
            </Box>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                marginTop: '16px',
              }}>
              <Box className={classes.over18LogoContainer}>
                <img src={Logo18.src} alt="18+" />
                <Typography
                  className={classes.over18Label}
                  sx={{ maxWidth: 40 }}>
                  <Trans id="footer.over18">Over 18 only</Trans>
                </Typography>
              </Box>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <img src={SSL.src} alt="ssl" />
                <Typography
                  className={classes.over18Label}
                  sx={{ maxWidth: 60 }}>
                  <Trans id="footer.ssl">256 bit SSL secure</Trans>
                </Typography>
              </Box>
            </Box>
          </Box>
          <Box
            sx={{
              display: 'block',
              margin: '0 48px',
              [QueryMapping.mobile]: {
                display: 'none',
              },
            }}>
            <ContactUs />
          </Box>
          <Box
            sx={{
              display: 'inline-flex',
              gap: 3,
              mr: 6,
              [QueryMapping.mobile]: {
                display: 'none',
              },
            }}>
            {selects}
          </Box>

          <Box
            component="a"
            sx={{ flex: 1, textAlign: 'right' }}
            href="https://accreditation.curacao-egaming.com/validateview.aspx?domain=ubet.io"
            target="_blank">
            <img
              src="https://accreditation.curacao-egaming.com/validate.ashx?domain=ubet.io"
              alt=""
              width={68}
              className={classes.curacaoLogo}
            />
          </Box>
        </Box>

        <Box
          sx={{
            display: 'none',
            [QueryMapping.mobile]: {
              display: 'block',
            },
          }}>
          <ContactUs />
        </Box>
        <Box
          sx={{
            mt: 3,
            display: 'none',
            [QueryMapping.mobile]: {
              display: 'flex',
              flexWrap: 'wrap',
              gap: 3,
            },
          }}>
          {selects}
        </Box>
        <Box sx={{ mt: 3 }}>
          <Typography
            sx={{ marginBottom: '16px !important' }}
            fontWeight="bold">
            <Trans id="footer.info">Info</Trans>
          </Typography>
          <Box
            sx={{
              display: 'grid',
              gridAutoFlow: 'column',
              gridColumnGap: '10px',
              gridTemplateColumns: 'repeat(3, 1fr)', // Ensure 3 columns are always present
              [QueryMapping.mobile]: {
                display: 'inline-grid',
                gridTemplateColumns: 'none',
              },
              '&::after': {
                content: '""',
                display: 'block',
              },
            }}>
            <Link
              href={'https://help.ubet.io/en/'}
              target="_blank"
              sx={{
                marginBottom: '16px',
                fontSize: '12px',
                cursor: 'pointer',
                textDecoration: 'none',
                color: 'common.white',
                fontWeight: 'bold',
              }}>
              {'Help'}
            </Link>
            <Link
              href={'/affiliates'}
              target="_blank"
              sx={{
                marginBottom: '16px',
                fontSize: '12px',
                cursor: 'pointer',
                textDecoration: 'none',
                color: 'common.white',
                fontWeight: 'bold',
              }}>
              {'Affiliates'}
            </Link>
          </Box>
        </Box>
        <Box sx={{ mt: 3 }}>
          <Typography gutterBottom fontWeight="bold">
            <Trans id="footer.policies">Policies</Trans>
          </Typography>

          <Box
            sx={{
              display: 'grid',
              gridAutoFlow: 'column',
              gridTemplateRows: { xs: 'repeat(5, 1fr)', sm: 'repeat(3, 1fr)' },
              columnGap: { xs: 1, sm: '20px' },
              rowGap: 2,
            }}>
            {legals.map((x) => (
              <MyLink
                key={x?.slug}
                color="inherit"
                href={x.link ?? '#'}
                target="_blank"
                sx={{ fontSize: 11 }}>
                {x?.title}
              </MyLink>
            ))}
            <MyLink
              color="inherit"
              href={Paths.Blogs}
              sx={{ fontSize: 11 }}
              target="_blank">
              <Trans id="footer.blog">Blog</Trans>
            </MyLink>
          </Box>
        </Box>
        <Typography
          mt={3}
          component="div"
          pt={2}
          display="block"
          variant="caption"
          color="custom.content.2"
          lineHeight={1.4}>
          <Trans id="footer.legal_stuffs">
            {
              'In order to register for this website, the user is required to accept the '
            }
            <MyLink
              color="inherit"
              underline="always"
              display="inline"
              href={generatePath(Paths.Legals, {
                tab: TERMS_AND_CONDITIONS_TAB,
              })}
              target="_blank">
              General Terms and Conditions.
            </MyLink>
            <br />
            <br />
            In the event the General Terms and Conditions are updated, existing
            users may choose to discontinue using the products and services
            before the said update shall become effective, which is a minimum of
            two weeks after it has been announced.
            <br />
            <br /> ubet.io is operated by BetU Curacao B.V registered under
            No.158488 at, Johan Van Walbeeckplein 24.
            <br />
            <br /> This website is licensed and regulated by Curaçao eGaming
            (Curaçao license No. 1668 JAZ issued by Curaçao eGaming).
          </Trans>
        </Typography>
      </Container>
    </Box>
  );
};

const ContactUs = () => {
  return (
    <Box>
      <Typography sx={{ marginBottom: '16px !important' }} fontWeight="bold">
        <Trans id="footer.contactus">Contact us</Trans>
      </Typography>
      <Socials />
    </Box>
  );
};
