import { useAtomValue } from 'jotai';
import { stakeCoinAtom } from 'src/store/auth';
import { fiatAtom } from 'src/store/layout';
import { getIconSrc } from 'src/utils/constants/icon-coins';

interface StakeCoinIconProps extends React.ImgHTMLAttributes<HTMLImageElement> {
  symbol?: string;
  fiat?: boolean;
}

// priority: props -> fallback
export const StakeCoinIcon: React.FCC<StakeCoinIconProps> = ({
  symbol: _symbol,
  fiat: _fiat,
  ...imgProps
}) => {
  const defaultFiat = useAtomValue(fiatAtom);
  const defaultSymbol = useAtomValue(stakeCoinAtom);

  const fiat = _fiat ?? defaultFiat;

  if (fiat) {
    return <>{' USD'}</>;
  }

  // if (!balance) return null;
  const symbol = _symbol ?? defaultSymbol;

  const src = imgProps.src ?? getIconSrc(symbol);
  const alt = imgProps.alt ?? symbol;
  const width = imgProps.width ?? 20;
  const height = imgProps.height ?? 20;

  return (
    <img {...imgProps} width={width} height={height} src={src} alt={alt} />
  );
};
