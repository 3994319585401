import { useCallback, useEffect, useState } from 'react';
import { useAsyncFn } from 'react-use';
import { getDialogBet as getMultiDetailBet } from 'src/api/sportbook-v2/ticket';
import { useLocale } from 'src/hooks/use-locale';
import { BetGame } from 'src/utils/types/my-bet-type';

const initialMeta = {
  payout: '0',
  status: '',
  total_odds: '0',
  total_stake: '0',
  page: 1,
  page_count: 0,
  total_count: 0,
  roomId: '',
  type: 'SINGLE',
  roomType: '',
  username: '',
  odds: '',
  rank: '',
  createdAt: '',
};
export const useBetFetcher = (betId?: string) => {
  const [attempt, setAttempt] = useState(1);
  const [items, setItems] = useState<BetGame[]>();
  const [meta, setMeta] = useState(initialMeta);
  const { locale } = useLocale();

  const fetcher = useCallback(
    async (id: string, _page: number, _pageSize: number) => {
      const { meta: _meta, items: _data } = await getMultiDetailBet({
        ticketId: id,
        page: _page,
        size: _pageSize,
        lang: locale,
      });
      setItems(_data);
      setMeta({
        payout: _meta.payout,
        status: _meta.status,
        total_odds: _meta.totalOdds,
        total_stake: _meta.stake,
        page: _page,
        page_count: Math.ceil(_meta.total_count / _pageSize),
        total_count: _meta.total_count,
        roomId: _meta.roomId,
        type: _meta.type,
        roomType: _meta.roomType,
        username: _meta.username,
        odds: _meta.odds,
        rank: _meta?.rank || '',
        createdAt: _meta.createdAt,
      });

      return true;
    },
    [locale],
  );

  const [{ loading }, doFetch] = useAsyncFn(fetcher);

  useEffect(() => {
    if (!betId) return;

    doFetch(betId, meta.page, 6);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [betId, meta.page, attempt]);

  const nextPage = (_page: number) => {
    if (_page <= meta.page_count) {
      setMeta((prev) => ({ ...prev, page: _page }));
    }
  };

  const getAllBet = () => {
    if (!betId) return;
    doFetch(betId, meta.page, 1000);
  };
  const reset = useCallback(() => {
    setMeta(initialMeta);
    setItems(undefined);
  }, []);

  return {
    bets: items,
    meta,
    loading: loading && !items,
    nextPage,
    retry: () => setAttempt((prev) => prev++),
    reset,
    getAllBet,
  };
};
