import { Box, BoxProps, IconButton, Modal, Stack } from '@mui/material';
import React, { useState } from 'react';
import CloseIcon from 'src/assets/icons/close.svg';
import SearchIcon from 'src/assets/icons/search.svg';
import { QueryMapping } from 'src/hooks/use-breakpoint';
import { SearchInput } from './search-input';

const MobileSearchBar: React.FCC<BoxProps> = (props) => {
  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);

  const handleClose = () => setOpen(false);

  return (
    <Box {...props}>
      <IconButton sx={{ outline: 'none!important' }} onClick={handleOpen}>
        <SearchIcon width="16px" height="16px" />
      </IconButton>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
        sx={{ backgroundColor: 'common.black' }}>
        <Stack direction="row" p="16px 0px">
          <Box sx={{ flexGrow: 1 }}>
            <SearchInput onSearch={handleClose} />
          </Box>
          {/* <Button
            variant="text"
            onClick={handleClose}
            sx={{ color: 'custom.content.2', p: 0 }}>
            <Trans id="common.cancel">Cancel</Trans>
          </Button> */}
          <IconButton onClick={handleClose} size="large">
            <CloseIcon />
          </IconButton>
        </Stack>
      </Modal>
    </Box>
  );
};

export const SearchBar: React.FCC = () => {
  return (
    <>
      <SearchInput
        sx={{
          minWidth: 140,
          flex: 1,
          [QueryMapping.mobile]: { display: 'none' },
        }}
      />

      <MobileSearchBar
        sx={{
          flex: 1,
          mx: 1,
          textAlign: 'end',
          display: 'none',
          [QueryMapping.mobile]: {
            display: 'block',
          },
        }}
      />
    </>
  );
};
