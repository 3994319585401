import { QueryMapping } from 'src/hooks/use-breakpoint';
import { makeStyles } from 'tss-react/mui';

export const useErrorStyles = makeStyles()((theme) => {
  const mobile = QueryMapping.mobile;

  return {
    root: {
      display: 'flex',
      flexDirection: 'column',
      background: `linear-gradient(180deg, ${theme.custom.bg[3]} 14.65%, ${theme.palette.primary.main} 218.19%)`,
    },
    top: {
      position: 'relative',
      flex: `0 1 ${(10 / 19) * 100}%`,
    },

    triangle: {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
    },

    triangle1: {
      height: '100%',
      backgroundColor: theme.palette.primary.main,
      WebkitClipPath: 'polygon(0 100%, 100% 0, 0 0)',
      clipPath: 'polygon(0 100%, 100% 0, 0 0)',
    },
    triangle2: {
      height: '50%',
      backgroundColor: theme.palette.primary.dark,
      WebkitClipPath: 'polygon(100% 100%, 100% 0, 0 0)',
      clipPath: 'polygon(100% 100%, 100% 0, 0 0)',
    },

    bottom: {
      padding: 12,
      flex: 1,
      textDecoration: 'none',
      transition: 'opacity 0.2s',
      '& > *': {
        fontSize: 16,
        lineHeight: 5 / 4,
        fontWeight: 700,
        textAlign: 'center',
        color: theme.custom.content[1],
      },
    },
    subtitle: {
      fontSize: 12,
      marginTop: 8,
    },
  };
});

export const useStyles = makeStyles()((theme) => {
  const mobile = QueryMapping.mobile;

  return {
    root: {
      borderRadius: 8,
      overflow: 'hidden',
    },

    image: {
      width: '100%',
      height: '100%',
      objectFit: 'fill',
      pointerEvents: 'none',
    },

    displayType: {
      display: '-webkit-box',
      WebkitBoxOrient: 'vertical',
      overflow: 'hidden',
      width: '100%',
    },

    title: {
      WebkitLineClamp: 5,
      fontSize: 16,
      lineHeight: 5 / 4,
      fontWeight: 600,
      wordWrap: 'break-word',
      [mobile]: {
        fontSize: 14,
      },
    },
    subtitle: {
      WebkitLineClamp: 1,
      marginTop: 8,
      fontSize: 12,
      lineHeight: 5 / 4,
      wordWrap: 'break-word',
      fontWeight: 600,
      [mobile]: {
        marginTop: 4,
      },
    },
    fav: {
      marginTop: 6,
      display: 'none',
      border: '1px solid',

      '&.logged': {
        display: 'flex',
      },
    },
    favIcon: {
      margin: '0px !important',
      backgroundColor: theme.palette.primary.main,
    },
  };
});
