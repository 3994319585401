import { Box } from '@mui/material';
import { useAtomValue } from 'jotai';
import { useSearchParams } from 'next/navigation';
import React, { useEffect } from 'react';
import { ChangeUsername } from 'src/_pages/home/main/components/change-username';
import { Footer } from 'src/components/modules/footer';
import { DetailDialog } from 'src/components/modules/leaderboard/sport/detail-dialog';
import { MultiBetDrawer } from 'src/components/modules/multi-bet-drawer';
import { NavBar } from 'src/components/modules/navbar';
import { BottomNav } from 'src/components/modules/navbar/bottom-nav';
import { DrawersOrLogin } from 'src/components/templates/basic-template/drawer-or-login';
import { Main } from 'src/components/templates/basic-template/main';
import { profileAtom } from 'src/store/auth';
import { clientCookie } from 'src/utils/helpers/headers/headers.client';

export const BasicTemplate: React.FCC = ({ children }) => {
  const searchParams = useSearchParams();
  const profile = useAtomValue(profileAtom);
  const userId = profile?.id;
  const hasUsernameChanged = profile?.hasUsernameChanged;

  useEffect(() => {
    const btag = searchParams.get('btag');
    // if there is a btag in the url , store it in the cookies
    if (btag) {
      // Check if a cookie already exists
      const storedBtag = clientCookie.get('btag');
      // if a btag is stored, ignore the new one
      if (storedBtag) {
        return;
      }
      // Store the btag in the cookies for 30 days
      clientCookie.set('btag', btag, { maxAge: 30 * 24 * 60 * 60 });
    }
  }, [searchParams]);

  return (
    <Main>
      <DetailDialog />
      <NavBar />
      <DrawersOrLogin />
      <Box component="main" sx={{ py: { xs: 2, md: 3 }, flexGrow: 1 }}>
        {children}
      </Box>
      <BottomNav />
      <Footer />

      <MultiBetDrawer />

      {userId && !hasUsernameChanged && <ChangeUsername />}
    </Main>
  );
};
