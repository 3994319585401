/* eslint-disable react/no-unescaped-entities */
import { Trans } from '@lingui/macro';
import { Box, Link, Typography } from '@mui/material';
import { atom, useSetAtom } from 'jotai';
import _ from 'lodash';
import React from 'react';
import InfoSvg from 'src/assets/icons/info.svg';
import { betSlipsAtom } from 'src/store/bet-slip';
import { MAX_BET } from 'src/utils/constants/bet-constant';

export const ignoredAtom = atom(false);

const removeInvalidBetsAtom = atom(null, (get, set) => {
  const items = get(betSlipsAtom);
  if (items.length < 2) {
    return;
  }

  const [first] = items;
  const clean = _.chain(items)
    .take(MAX_BET)
    .filter((x) => x.source === first.source)
    .groupBy((x) => x.eventId)
    .mapValues((x) => _.first(x))
    .values()
    .compact()
    .value();

  set(betSlipsAtom, clean);
});

const Btns: React.FC = () => {
  const setIgnored = useSetAtom(ignoredAtom);
  const fixErrors = useSetAtom(removeInvalidBetsAtom);
  const handleClick = (fn: () => any) => {
    return (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      e.stopPropagation();
      fn();
    };
  };

  return (
    <>
      <Link
        onClick={handleClick(() => {
          setIgnored(true);
        })}
        underline="always"
        color="inherit"
        sx={{ m: -1, p: 1, fontWeight: 700 }}
        component="button">
        <Trans id="multi_bet.ignore">Ignore</Trans>
      </Link>

      <div style={{ flex: '0 1 24px' }} />

      <Link
        onClick={handleClick(fixErrors)}
        underline="always"
        color="inherit"
        sx={{ m: -1, p: 1, fontWeight: 700 }}
        component="button">
        <Trans id="multi_bet.remove">Remove</Trans>
      </Link>
    </>
  );
};

const Broken: React.FC = (props) => {
  return (
    <>
      <Box flexShrink={0} component={InfoSvg} width={24} height={24} />
      <Typography noWrap sx={{ fontSize: 13, mx: 1, flex: 1 }}>
        <Trans id="multi_bet.broken">Bet breaks your multi.</Trans>
      </Typography>

      <Btns />
    </>
  );
};

const CrossBetError: React.FC = () => {
  return (
    <>
      <Box flexShrink={0} component={InfoSvg} width={24} height={24} />
      <Typography sx={{ fontSize: 13, mx: 1, flex: 1 }}>
        <Trans id="multi_bet.cross_bet">
          Can't combine sports, eSports & promo bets into a multi.
        </Trans>
      </Typography>

      <Btns />
    </>
  );
};

interface HasErrorProps {
  crossBet?: boolean;
}

export const HasError: React.FCC<HasErrorProps> = ({ crossBet }) => {
  if (crossBet) {
    return <CrossBetError />;
  }

  return <Broken />;
};
