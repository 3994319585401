import { Tabs, TabsProps } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
interface ScrollableTabsProps extends TabsProps {
  indicatorClass?: string;
}

const useStyles = makeStyles()((theme) => {
  return {
    fullHeight: {
      height: '100%',
    },
    tabs: {
      minHeight: 'unset',
      height: '100%',
      padding: theme.spacing(0, 3),
      position: 'relative',
    },

    tabsIndicator: {
      backgroundColor: 'transparent',
      display: 'flex',
      justifyContent: 'center',
      '& > span': {
        backgroundColor: theme.palette.primary.main,
        height: 2,
        width: '100%',
        borderTopLeftRadius: 2,
        borderTopRightRadius: 2,
      },
    },

    scrollBtnTabs: {
      position: 'absolute',
      top: '50%',
      transform: 'translateY(-50%)',
      '&:first-child': { left: 0 },
      '&:last-child': { right: 0 },
      width: 24,
      opacity: 1,
    },
  };
});

export const ScrollableTabs: React.FCC<ScrollableTabsProps> = ({
  indicatorClass,
  ...props
}) => {
  const { classes, cx } = useStyles();

  return (
    <Tabs
      {...props}
      className={cx(classes.tabs, props.className)}
      variant="scrollable"
      classes={{
        ...props.classes,
        flexContainer: cx(classes.fullHeight, props.classes?.flexContainer),
        scrollButtons: cx(classes.scrollBtnTabs, props.classes?.scrollButtons),
      }}
      textColor="primary"
      indicatorColor="primary"
      TabIndicatorProps={{
        className: cx(classes.tabsIndicator, indicatorClass),
        children: <span />,
      }}
    />
  );
};
